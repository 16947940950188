/**
 * 렌탈료정보
 */
export interface SrRentFee {
  // 상품ID
  prodId: string;

  // 계약기간
  cntrTerm: string;

  // 계약구분코드
  custClsCd: string;
  custClsNm: string;

  // 운전자범위
  drvSoe: string;
  drvSoeNm: string;

  // 주행거리
  prmsDtc: string;
  prmsDtcNm: string;

  // 표준렌탈료
  rglRentAmt: string;
}

/**
 * 렌탈료 조회
 */
export class NcRentFeeSetResVO {
  // 신차12개월 렌탈료 목록 수
  srRentFeelistCnt = 0;

  // 신차12개월 렌탈료 목록
  srRentFeelist: SrRentFee[] = [];
}

/**
 * 렌탈료 저장
 */
export class RentFeeSaveVO {
  // 상품ID
  prodId = "";

  // 계약기간
  cntrTerm = "";

  // 계약구분코드
  custClsCd = "";

  // 운전자 범위
  drvSoe = "";

  // 주행거리
  prmsDtc = "";

  // 표준렌탈료
  rglRentAmt = "";
}