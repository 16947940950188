import React from "react";
import styled from "styled-components";

import { ModalProps } from "../../../../../../types";
import TagMaster from "../../../../../../../models/TagMaster";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";

// utils
import { formatNumber } from "@utils/common-utils";
// modles
import McCarCatalogDetail from "@models/McCarCatalogDetail";
import { getPcTagStyles, TagStyleCode } from "@components/shared/composition/TagMasterSelect/utils/utils";
import { Tag } from "antd";

interface Props extends ModalProps {
  tagMasters?: TagMaster[];
  catalogDetail?: McCarCatalogDetail;
}

const TagPreviewModal: React.FC<Props> = (props: Props) => {
  const { tagMasters = [], catalogDetail, visible, onCancel } = props;

  if (!tagMasters) {
    return null;
  }

  const renderPreview = () => {
    if (catalogDetail) {
      const { prodNm, carGradeNm, yearType, curTravelDtc, innrCarColorNm, innrColorImgUrl, xtnlCarColorNm, xtnlColorImgUrl, dtlTagList } = catalogDetail;
      return (
        <div>
          <PlatformLabel>PC</PlatformLabel>
          <GreyContainer>
            <CenterWrapper>
              <PCCardContainer>
                <article>
                  {Number(curTravelDtc) < 100 && <span>신차</span>}
                  {/* {100 < Number(curTravelDtc) && Number(curTravelDtc) < 5000 && Number(carAge) < 12 && <span>신차급</span>} */}
                  {dtlTagList.map(({ pcTagTxt, pcTagStyl }) => (
                    <Tag
                      style={{
                        ...getPcTagStyles(pcTagStyl as TagStyleCode),
                      }}
                    >
                      {pcTagTxt}
                    </Tag>
                  ))}
                </article>
                <p className="title">{prodNm} {carGradeNm}</p>
                <p>{yearType}연식 {formatNumber(curTravelDtc)}km</p>
                <div>
                  <div>
                    <span>외장 <em><img src={xtnlColorImgUrl} /></em> <strong>{xtnlCarColorNm}</strong></span>
                  </div>
                  <div>
                    <span>내장 <em><img src={innrColorImgUrl} /></em> <strong>{innrCarColorNm}</strong></span>
                  </div>
                </div>
              </PCCardContainer>
            </CenterWrapper>
          </GreyContainer>
          <PlatformLabel
            style={{
              marginTop: "24px",
            }}
          >
            모바일(리스트)
          </PlatformLabel>
          <GreyContainer>
              <MobileListContainer>
                <article>
                  {Number(curTravelDtc) < 100 && <span>신차</span>}
                  {/* {100 < Number(curTravelDtc) && Number(curTravelDtc) < 5000 && Number(carAge) < 12 && <span>신차급</span>} */}
                  {dtlTagList.map(({ mobTagStyl, mobTagTxt }) => (
                    <Tag
                      style={{
                        ...getPcTagStyles(mobTagStyl as TagStyleCode),
                      }}
                    >
                      {mobTagTxt}
                    </Tag>
                  ))}
                </article>
                <p className="title">{prodNm} {carGradeNm}</p>
                <p>{yearType}연식 {formatNumber(curTravelDtc)}km</p>
                <div>
                  <div>
                    <span>외장 <em><img src={xtnlColorImgUrl} /></em> <strong>{xtnlCarColorNm}</strong></span>
                  </div>
                  <div>
                    <span>내장 <em><img src={innrColorImgUrl} /></em> <strong>{innrCarColorNm}</strong></span>
                  </div>
                </div>
              </MobileListContainer>
          </GreyContainer>
        </div>
      );
    }

    return "";
  };

  return (
    <Modal
      title="태그 미리보기"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {renderPreview()}
    </Modal>
  );
};

const PlatformLabel = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  display: inline-block;
`;

const GreyContainer = styled.div`
  background-color: #f6f6fa;
  padding: 16px;
  text-align: center;
`;

const CenterWrapper = styled.div`
  display: grid;
  display: inline-block;
  text-align: left;
`;

const PCCardContainer = styled.div<any>`
  width: 219px;
  box-sizing: border-box;
  border: 1px solid rgb(226, 227, 231);
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 20px !important;
  background-color: #fff;
  >.title{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgb(0, 0, 0);
    width: 177px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
  >p{
    position: relative;
    font-size: 12px;
    color: rgb(119, 119, 122);
    font-weight: 500;
    /* margin-bottom: 41px; */
    margin-top: 5px;
    span{

    }
    strong{
      position: absolute;
      bottom: -29px;
      left: 0;
      em{
        font-style: initial;
      }
    }
  }
  article{
    /* TODO: 스타일 수정 필요 */
    span {font-size:12px;  color:#FF7A00; font-weight:700; padding: 1px 7px; background:#FFF2E5; border-radius:.36rem; margin-left:.8rem;display:inline-block;line-height:21px;margin:0 0 0 10px !important; }
    span:first-child{margin:0 !important;}
    span.mcNewTag{color:#F469B4;background:#FFEEFB;}
  }
  >div{
    >div{
      font-weight: 400;
      line-height: 2.1rem;
      display: -webkit-inline-box;
      >span{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: -webkit-inline-box;
        line-height: 14px;
        >em{
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          overflow: hidden;
          width: 16px;
          min-width: 16px;
          min-height: 16px;
          height: 16px;
          margin: 0 4px;
          background:#000;
        }
        >strong{
          font-weight: 900;
          text-overflow: ellipsis;
          width: 129px;
          display: table-caption;
          overflow: hidden;
          white-space: nowrap;
          float: right;
          padding-left: 2px;
          line-height: 14px;
        }
      }
    }
  }
`;

const MobileListContainer = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border: 1px solid rgb(226, 227, 231);
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 12px 18px !important;
  background-color: #fff;
  text-align: left;
  >.title{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgb(0, 0, 0);
    width: 177px;
    min-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
  >p{
    font-size: 12px;
    color: rgb(119, 119, 122);
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 5px;
    
    strong{
      position: initial !important;
      margin-left: 10px;
      em{
        font-style: initial;
      }
    }
  }
  article{
    /* TODO: 스타일 수정 필요 */
    span {font-size:12px;  color:#FF7A00; font-weight:700; padding: 1px 7px; background:#FFF2E5; border-radius:.36rem; margin-left:.8rem;display:inline-block;line-height:21px;margin:0 0 0 10px !important;}
    span:first-child{margin:0 !important;}
    span.mcNewTag{color:#F469B4;background:#FFEEFB;}
  }
  >div{
    >div{
      &:first-child{
        margin-bottom: 10px;
      }
      >span{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: -webkit-inline-box;
        >em{
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          overflow: hidden;
          width: 16px;
          min-width: 16px;
          min-height: 16px;
          height: 16px;
          margin: 3px 6px 0px 8px;
          background:#000;
        }
        >strong{
          font-weight: 900;
          text-overflow: ellipsis;
          width: unset;
          display: table-caption;
          overflow: hidden;
          white-space: nowrap;
          float: right;
          padding-left: 2px;
        }
      }
    }
  }
`;

export default TagPreviewModal;