/* eslint-disable no-console */
import * as Parallel from "async-parallel";
import imageCompression from "browser-image-compression";
import { plainToClass } from "class-transformer";
import { UsedCarCatalog } from "../../models/UsedCarCatalog";
import { ListItemResponse, ListRequestParams } from "../types";
import { HttpMethod, request } from "../../network/request";
import { ROOT_URL } from "../../network/api-urls";
import UsedCarCatalogDetail from "../../models/UsedCarCatalogDetail";
import UploadResult from "../../models/UploadResult";
import { RentFeeTuneSaveVO } from "../../models/RentFeeSet";
import { TkvAmtTuneSaveVO } from "../../models/TkvAmtSetResListVO";

const apiRootUrl = `${ROOT_URL}/admin/uc-catalogs`;

export interface GetUsedCarCatalogRequestParams extends ListRequestParams {
  // 등록일자 조회시작일자
  regStDt?: string;
  // 등록일자 조회종료일자
  regEndDt?: string;
  // COSS ID
  cossId?: string;
  // 차량번호
  carNo?: string;
  // 제조사
  brandNm?: string;
  // 모델
  repCarClassNm?: string;
  // 세부모델
  carClassNm?: string;
  // 트림
  carGradeNm?: string;
  // 연식
  yearType?: string;
  // 주행거리시작
  startLastDtc?: string;
  // 주행거리종료
  endLastDtc?: string;
  // 연료
  fuel?: string;
  // 변속
  istdTrans?: string;
  // 차량정보
  carNmInfo?: string;
  // 정비이력
  fixYn?: string;
  // niceDnr연결여부
  niceConYn?: string;
  // 상품유형
  ncarClsFlag?: string;
  // 노출여부
  viewYn?: string;
}

/**
 * 중고장기 상품 리스트 조회
 */
export const fetchUCCatalogs = async (
  params?: GetUsedCarCatalogRequestParams
): Promise<ListItemResponse<UsedCarCatalog>> => {
  const res = await request(
    HttpMethod.Get,
    apiRootUrl,
    params as Record<string, any>
  );

  const { data } = res;
  const { response } = data;
  const { list, totalCnt } = response;

  return {
    items: plainToClass(UsedCarCatalog, list as Array<JSON>),
    count: totalCnt,
  };
};

/**
 * 중고장기 상품 상세 조회
 */
export const fetchUCCatalogDetail = async (
  prodId: string
): Promise<UsedCarCatalogDetail> => {
  const url = `${apiRootUrl}/${prodId}`;
  const res = await request(HttpMethod.Get, url);

  const { data } = res;
  const { response } = data;
  return plainToClass(UsedCarCatalogDetail, response);
};

/**
 * 중고장기 상품 노출상태 변경
 */
export const editUCCatalogDisplayState = async (
  prodId: string,
  carId: string,
  viewYn: string,
  ncarClsFlag: string,
) => {
  const url = `${ROOT_URL}/admin/uc-prod-viewyn`;
  await request(HttpMethod.Put, url, undefined, {
    prodId,
    carId,
    viewYn,
    ncarClsFlag,
  });
};

/**
 * 중고장기 차량소개 변경
 */

export const editMdComment = async (
  prodId: string,
  carId: string,
  mdComment: string
) => {
  const url = `${ROOT_URL}/admin/uc-instruction-update`;
  await request(HttpMethod.Put, url, undefined, {
    prodId,
    carId,
    mdComment,
  });
};

/**
 * 중고장기 이미지 벌크 등록
 */
export const uploadUCImages = async (carId: string, files: any[]) => {
  /**
   * 1. 원본이미지 등록
   */
  const url = `${ROOT_URL}/com/multiuploadfiles`;
  const originFormData = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    originFormData.append("uploadfiles", files[i]);
    console.log(`originFile`, files[i]);
    console.log(`originFile size ${files[i].size / 1024 / 1024} MB`);
  }

  console.log("원본이미지 배열[업로드전]", files);

  const res = await request(
    HttpMethod.Post,
    url,
    { idtfClsCd: "687111", carId },
    originFormData,
    undefined,
    true
  );

  const { data } = res;
  const { response } = data;
  const list = plainToClass(UploadResult, response as JSON[]);
  console.log("원본이미지 응답[업로드후]", list);

  /**
   * 2. 리사이즈 이미지 등록
   */
  const resizeUrl = `${ROOT_URL}/admin/uc-multiimage-reg`;

  const imgIds = list.map(({ imgId }) => imgId);
  const resizedFormData = new FormData();
  const options = {
    maxSizeMB: 0.1,
    useWebWorker: true,
  };
  const compressedImages: any[] = [];
  try {
    await Parallel.each(
      files,
      async (file) => {
        const compressed = await imageCompression(file, options);
        // Blob to File
        let compressedFile = compressed;
        if (compressed instanceof Blob) {
          compressedFile = new File([compressed], `${file.name}`);
        }
        compressedImages.push(compressedFile);
        console.log(`compressedFile`, compressedFile);
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        );
        resizedFormData.append("uploadfiles", compressedFile);
      },
      1
    );
  } catch (e) {
    console.error(e);
  }
  console.log("리사이즈이미지[업로드전]", compressedImages);

  await request(
    HttpMethod.Post,
    resizeUrl,
    { UploadImgId: imgIds },
    resizedFormData,
    undefined,
    true
  );
};

export interface UCImageUnlinkParam {
  imgId: string;
  carId: string;
}

/**
 * 중고장기 이미지 삭제
 */

export const unlinkUCImage = async (
  carId: string,
  param: UCImageUnlinkParam
) => {
  const url = `${ROOT_URL}/admin/uc-image-delete`;

  await request(HttpMethod.Put, url, undefined, {
    carId,
    ...param,
  });
};

/**
 * 중고장기 이미지 벌크 삭제
 */
export const unlinkUCImages = async (
  carId: string,
  params: UCImageUnlinkParam[]
) => {
  await Parallel.each(params, async (param) => {
    await unlinkUCImage(carId, param);
  });
};

interface ImageSortOrderParam {
  imgId: string;
  viewSeq: string;
}

/**
 * 중고장기 이미지 게시순번 변경
 */

export const editImageSortOrders = async (params: ImageSortOrderParam[]) => {
  const url = `${ROOT_URL}/admin/uc-image-seq`;
  await request(HttpMethod.Put, url, undefined, params);
};

/**
 * 중고장기 상품 인수가 설정
 */
export const getUcTkvAmtSet = async (params: {
  makerId: string;
  modeGrdId: string;
  carTypeId: string;
  carTypeDtlId: string;
  modlId: string;
  carId: string;
  modeProdId: string;
}) => {
  const url = `${ROOT_URL}/admin/uc-tkv-amt-set`;
  const { data } = await request(HttpMethod.Get, url, params);
  return data;
};

/**
 * 중고장기 인수가 세팅 데이터 수정
 */
export const editUcTkvAmtSet = async (body: TkvAmtTuneSaveVO[]) => {
  const url = `${ROOT_URL}/admin/uc-tkv-amt-set-save`;
  const { data } = await request(HttpMethod.Put, url, undefined, body);
  return data;
};

/**
 * 중고장기 인수가 세팅 데이터 초기화
 */
export const resetUcTkvAmt = async (modeProdId: string) => {
  const url = `${ROOT_URL}/admin/uc-tkv-amt-set-delete`;
  const { data } = await request(HttpMethod.Post, url, {
    modeProdId,
  });
  return data;
};

/**
 * 중고장기 인수가 설정 취소시 설정한 값이 없으면 삭제
 */
export const deleteUcTkvAmtWhenNoSetFlag = async (modeProdId: string) => {
  const url = `${ROOT_URL}/admin/uc-tkv-amt-set-check-delete`;
  const { data } = await request(HttpMethod.Post, url, {
    modeProdId,
  });
  return data;
};

/**
 * 중고장기 상품 렌탈료 설정
 */
export const getUcRntFeeSet = async (params: {
  makerId: string;
  modeGrdId: string;
  carTypeId: string;
  carTypeDtlId: string;
  modlId: string;
  carId: string;
  modeProdId: string;
  distanceKm: number;
}) => {
  const url = `${ROOT_URL}/admin/uc-rnt-fee-set`;
  const { data } = await request(HttpMethod.Get, url, params);
  return data;
};

/**
 * 중고장기 렌탈료 세팅 데이터 수정
 */
export const editUcRntFeeSet = async (body: RentFeeTuneSaveVO[]) => {
  const url = `${ROOT_URL}/admin/uc-rnt-fee-set-save`;
  const { data } = await request(HttpMethod.Put, url, undefined, body);
  return data;
};

/**
 * 중고장기 렌탈료 세팅 데이터 초기화
 */
export const resetUcRntAmt = async (modeProdId: string) => {
  const url = `${ROOT_URL}/admin/uc-rnt-fee-set-delete`;
  const { data } = await request(HttpMethod.Post, url, {
    modeProdId,
  });
  return data;
};

/**
 * 중고장기 렌탈료 설정 취소시 설정한 값이 없으면 삭제
 */
export const deleteUcRntFeeWhenNoSetFlag = async (modeProdId: string) => {
  const url = `${ROOT_URL}/admin/uc-rnt-fee-set-check-delete`;
  const { data } = await request(HttpMethod.Post, url, {
    modeProdId,
  });
  return data;
};

interface EditUcYearTypeParam {
  modeGrdId: string;
  cossModlId: string;
  yearType: string;
}

/**
 * 중고장기 연식 수정
 */
export const updateUsdCarYearUpd = async (body: EditUcYearTypeParam) => {
  const url = `${ROOT_URL}/admin/uc-car-year-upd`;
  const { data } = await request(HttpMethod.Put, url, undefined, body);

  return data;
};

interface EditUcProdTypeParam {
  prodId: string;
  ncarClsFlag: string;
}

/**
 * 중고장기 상품유형 수정
 */
export const editUcProdType = async (body: EditUcProdTypeParam) => {
  const url = `${ROOT_URL}/admin/uc-carcls-update`;
  const { data } = await request(HttpMethod.Put, url, undefined, body);

  return data;
};

interface EditUcOptionParam {
  modeGrdId: string;
  cossModlId: string;
  optNm: string;
  optSts: string;
}

/**
 * 중고장기 옵션 수정
 */
export const updateUsdCarOptStsUpd = async (body: EditUcOptionParam) => {
  const url = `${ROOT_URL}/admin/uc-car-opt-sts-upd`;
  const { data } = await request(HttpMethod.Put, url, undefined, body);

  return data;
};

/**
 * 중고장기 상품 3D뷰어 사용여부 수정(PUT: ~​/admin​/uc-prod-3d-yn)
 */
export const updateUC3dYnState = async (prodId: string, carId: string, view3dYn: string) => {
  const url = `${ROOT_URL}/admin/uc-prod-3d-yn?prodId=${prodId}&carId=${carId}&view3dYn=${view3dYn}`;
  const { data } = await request(HttpMethod.Put, url, undefined, undefined);

  return data;
};

/**
 * 상품 태그 연결
 */
export const linkTag = async (prodId: string, tagId: string) => {
  const url = `${ROOT_URL}/admin/uc-catalogs-tag`;
  await request(HttpMethod.Post, url, undefined, {
    prodId,
    tagId,
    useYn: "Y",
  });
};

/**
 * 상품명으로 태그 연결
 */
export const linkTagToNm = async (prodNm: string, tagId: string) => {
  const url = `${ROOT_URL}/admin/mm-prod-tag`;
  await request(HttpMethod.Post, url, undefined, {
    prodNm,
    tagId,
    useYn: "Y",
  });
};

/**
 * 상품 태그 연결해제
 * - 중고장기 상품 태그 수정(PUT: ~​/admin​/uc-catalogs-tag​/{tagSeq})
 * - 월렌트 메인 상품 리스트 태그 수정(PUT: ~​/admin​/mm-prod-tag​/{tagSeq})
 */
export const unlinkTag = async (tagSeq: string, isMc?: boolean) => {
  const url = `${ROOT_URL}/admin/${isMc ? `mm-prod-tag` : `uc-catalogs-tag`}/${tagSeq}`;
  await request(HttpMethod.Put, url, undefined, {
    useYn: "N",
  });
};

/**
 * 상품 태그 벌크 연결
 */
export const linkTags = async (prodId: string, tagIds: string[]) => {
  await Parallel.each(tagIds, async (tagId) => {
    await linkTag(prodId, tagId);
  });
};

/**
 * 상품 태그 벌크 연결해제
 */
export const unlinkTags = async (tagSeqs: string[], isMc?: boolean) => {
  await Parallel.each(tagSeqs, async (tagSeq) => {
    await unlinkTag(tagSeq, isMc);
  });
};

/**
 * 상품명 태그 벌크 연결
 */
export const linkTagsToNm = async (prodNm: string, tagIds: string[]) => {
  await Parallel.each(tagIds, async (tagId) => {
    await linkTagToNm(prodNm, tagId);
  });
};

/**
 * 상품 비디오 등록
 */
export const createUcVideo = async (body: {
  prodId: string;
  videoRate: string;
  videoBtn: string;
  videoUrl: string;
  useYn: string;
}) => {
  const url = `${ROOT_URL}/admin/uc-catalogs-video`;
  await request(HttpMethod.Post, url, undefined, body);
};

/**
 * 상품 비디오 수정
 */
export const updateUcVideo = async (
  seq: string,
  body: {
    prodId: string;
    videoRate: string;
    videoBtn: string;
    videoUrl: string;
    useYn: string;
  }
) => {
  const url = `${ROOT_URL}/admin/uc-catalogs-video/${seq}`;
  await request(HttpMethod.Put, url, undefined, body);
};


/**
 * 중고장기 차량 세부모델 수정
 */
 export const updateUsdCarClassNmUpd = async (
  mdGrdId: string,
  updCarClassNm: string,
) => {
  const url = `${ROOT_URL}/admin/uc-car-class-nm-upd/${mdGrdId}/${updCarClassNm}`;
  const { data } = await request(HttpMethod.Put, url, undefined, undefined);

  return data;
};

/**
 * 중고장기 차량 트림명 수정
 */
export const updateUsdCarGradeNmUpd = async (
  body: {
    modeGrdId: string;
    carGradeNm: string;
  }
) => {
  const url = `${ROOT_URL}/admin/uc-car-grade-nm-upd`;
  const { data } = await request(HttpMethod.Put, url, undefined, body);

  return data;
};

/**
 * 중고장기 차량 옵션추가
 */
export const insertUsdCarOpt = async (
  body: {
    modeGrdId: string;
    cossModlId: string;
    optNm: string;
  }
) => {
  const url = `${ROOT_URL}/admin/uc-car-opt`;
  const { data } = await request(HttpMethod.Post, url, undefined, body);

  return data;
};