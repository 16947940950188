import React, { useState } from "react";

import { Alert, Button, Form, Input, Spin } from "antd";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../../../types";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";
import { StyledTable } from "../../../../../../../components/shared/data-display/Tables/components/StyledTable";
import {
  editUcRntFeeSet,
  getUcRntFeeSet,
  resetUcRntAmt,
} from "../../../../../../../apis/uc-catalogs";
import {
  RentFeeSetResVO,
  RentFeeTuneSaveVO,
} from "../../../../../../../models/RentFeeSet";
import {
  ENUM_DC_TYPE_PERCENT,
  ENUM_DC_TYPE_VALUE,
} from "../../../../../../../constants/enums";
import { alertError } from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
  onClose: () => void;
}

enum DcType {
  RATE,
  WON,
}

const RntFeeSetModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, catalogDetail, onClose } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [dcType, setDcType] = useState<DcType>(DcType.RATE);

  const [dcValRates, setDcValRates] = useState<number[]>([]);
  const [dcValWons, setDcValWons] = useState<number[]>([]);
  const [rntFeeSetlist, setRntFeeSetlist] = useState<RentFeeSetResVO[]>([]);

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      getUcRntFeeSet({
        carId: catalogDetail.carId,
        carTypeDtlId: catalogDetail.cartypeDtlId,
        carTypeId: catalogDetail.cartypeId,
        distanceKm: Number(catalogDetail.curTravelDtc),
        makerId: catalogDetail.carMakerId,
        modeGrdId: catalogDetail.modeGrdId,
        modeProdId: catalogDetail.prodId,
        modlId: catalogDetail.cossModlId,
      })
        .then(({ response }) => {
          setRntFeeSetlist(response.rntFeeSetlist);
          if (response.rntFeeSetlist[0].dcTypeCd !== ENUM_DC_TYPE_VALUE) {
            setDcType(DcType.RATE);
            setDcValRates(
              response.rntFeeSetlist.map(({ dcVal }: RentFeeSetResVO) => dcVal)
            );
            setDcValWons(response.rntFeeSetlist.map(() => 0));
          } else {
            setDcType(DcType.WON);
            setDcValRates(response.rntFeeSetlist.map(() => 0));
            setDcValWons(
              response.rntFeeSetlist.map(({ dcVal }: RentFeeSetResVO) => dcVal)
            );
          }
        })
        .finally(() => {
          setDataFetching(false);
        });
    }
  };

  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  const handleReset = async () => {
    try {
      setConfirmLoading(true);
      if (catalogDetail?.prodId) {
        await resetUcRntAmt(catalogDetail?.prodId);
        if (onDataChange) onDataChange(catalogDetail.prodId);
        if (onClose) onClose();
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSubmit = async () => {
    let body: RentFeeTuneSaveVO[] = [];
    if (dcType === DcType.RATE) {
      body = rntFeeSetlist.map(({ monthInfo, rntAmtTune }, index) => {
        return {
          modeProdId: catalogDetail?.prodId || "",
          carId: catalogDetail?.carId || "",
          monthInfo,
          dcTypeCd: ENUM_DC_TYPE_PERCENT,
          dcVal: dcValRates[index],
          finalRntAmt: Math.round(
            Number(rntAmtTune + (rntAmtTune * Number(dcValRates[index])) / 100)
          ),
        };
      });
    } else {
      body = rntFeeSetlist.map(({ monthInfo, rntAmtTune }, index) => {
        return {
          modeProdId: catalogDetail?.prodId || "",
          carId: catalogDetail?.carId || "",
          monthInfo,
          dcTypeCd: ENUM_DC_TYPE_VALUE,
          dcVal: dcValWons[index],
          finalRntAmt: Math.round(rntAmtTune + Number(dcValWons[index])),
        };
      });
    }
    try {
      setConfirmLoading(true);
      await editUcRntFeeSet(body);
      if (onDataChange) onDataChange();
      onClose();
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleDcValChange = (index: number, value: number) => {
    if (dcType === DcType.RATE) {
      const newDcVals = dcValRates.concat();
      newDcVals[index] = value;
      setDcValRates(newDcVals);
    } else {
      const newDcVals = dcValWons.concat();
      newDcVals[index] = value;
      setDcValWons(newDcVals);
    }
  };

  const handleDcTypeChange = (dcType: DcType) => {
    setDcType(dcType);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      title="렌탈료 수정"
      onCancel={onCancel}
      footer={[
        <Button
          key="reset"
          type="default"
          loading={confirmLoading}
          onClick={handleReset}
        >
          초기화
        </Button>,
        <Button key="cancel" type="default" onClick={onCancel}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={confirmLoading}
          onClick={handleSubmit}
        >
          확인
        </Button>,
      ]}
    >
      <Form.Item
        label="조정타입"
        colon={false}
        labelCol={{ span: 4 }}
        labelAlign="left"
      >
        <Button
          type={dcType === DcType.RATE ? "primary" : "default"}
          onClick={() => handleDcTypeChange(DcType.RATE)}
        >
          비율(%)
        </Button>
        <Button
          type={dcType === DcType.WON ? "primary" : "default"}
          onClick={() => handleDcTypeChange(DcType.WON)}
        >
          금액(₩)
        </Button>
      </Form.Item>
      {dataFetching ? (
        <Spin size="large" />
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>개월수</th>
              {rntFeeSetlist.map(({ monthInfo }) => {
                return <th>{monthInfo}개월</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>실주행거리</th>
              {rntFeeSetlist.map(({ realKm }, index) => {
                return (
                  <td key={index.toString()}>
                    {Number(realKm).toLocaleString()}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th>표준렌탈료</th>
              {rntFeeSetlist.map(({ rntAmt }, index) => {
                return (
                  <td key={index.toString()}>
                    {Number(rntAmt).toLocaleString()}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th>시스템조정가</th>
              {rntFeeSetlist.map(({ rntAmtTune }, index) => {
                return (
                  <td key={index.toString()}>
                    {Number(rntAmtTune).toLocaleString()}
                  </td>
                );
              })}
            </tr>
            <tr className="rate-control">
              <th>조정</th>
              {(dcType === DcType.RATE ? dcValRates : dcValWons).map(
                (dcVal, index) => {
                  return (
                    <td key={`${dcType}-${index.toString()}`}>
                      <Input
                        type="number"
                        onChange={({ target: { value } }) =>
                          handleDcValChange(index, Number(value))
                        }
                        defaultValue={dcVal}
                      />{" "}
                      {dcType === DcType.RATE ? "%" : ""}
                    </td>
                  );
                }
              )}
            </tr>
            <tr>
              <th>최종 표준렌탈료</th>
              {rntFeeSetlist.map(({ rntAmtTune }, index) => {
                if (dcType === DcType.RATE) {
                  return (
                    <td key={index.toString()}>
                      {Math.round(
                        Number(
                          rntAmtTune +
                            (rntAmtTune * Number(dcValRates[index])) / 100
                        )
                      ).toLocaleString()}
                    </td>
                  );
                }
                return (
                  <td>
                    {Number(
                      rntAmtTune + Number(dcValWons[index])
                    ).toLocaleString()}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </StyledTable>
      )}
    </Modal>
  );
};

RntFeeSetModal.defaultProps = {};
export default RntFeeSetModal;
