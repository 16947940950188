import React, { useState } from "react";

import { Input, Spin } from "antd";
import Modal from "../../../../../../../components/shared/feedback/antd/Modal";
import { ModalProps } from "../../../../../../types";
import UsedCarCatalogDetail from "../../../../../../../models/UsedCarCatalogDetail";
import { StyledTable } from "../../../../../../../components/shared/data-display/Tables/components/StyledTable";
import {
  editUcTkvAmtSet,
  getUcTkvAmtSet,
  resetUcTkvAmt,
} from "../../../../../../../apis/uc-catalogs";
import { TkvAmtSetResListVO } from "../../../../../../../models/TkvAmtSetResListVO";
import Button from "../../../../../../../components/shared/general/antd/Button";
import { alertError } from "../../../../../../../utils/render-utils";
import { getErrorMessage } from "../../../../../../../utils/common-utils";

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
  onClose: () => void;
}

const TkvAmtSetModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, onDataChange, catalogDetail, onClose } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [tkvAmtSetlist, setTkvAmtSetlist] = useState<TkvAmtSetResListVO[]>([]);
  const [dcVals, setDcVals] = useState<number[]>([]);

  const onModalOpen = async () => {
    setDataFetching(true);
    if (catalogDetail) {
      getUcTkvAmtSet({
        carId: catalogDetail.carId,
        carTypeDtlId: catalogDetail.cartypeDtlId,
        carTypeId: catalogDetail.cartypeId,
        makerId: catalogDetail.carMakerId,
        modeGrdId: catalogDetail.modeGrdId,
        modeProdId: catalogDetail.prodId,
        modlId: catalogDetail.cossModlId,
      })
        .then(({ response }) => {
          setTkvAmtSetlist(response.tkvAmtSetlist);
          setDcVals(
            response.tkvAmtSetlist.map(
              ({ tkvAmtTune }: TkvAmtSetResListVO) => tkvAmtTune
            )
          );
        })
        .finally(() => {
          setDataFetching(false);
        });
    }
  };

  /**
   * Event Actions
   */
  const handleReset = async () => {
    try {
      setConfirmLoading(true);
      if (catalogDetail?.prodId) {
        await resetUcTkvAmt(catalogDetail?.prodId);
        if (onDataChange) onDataChange(catalogDetail.prodId);
        if (onClose) onClose();
      }
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSubmit = async () => {
    const body = tkvAmtSetlist.map(({ monthInfo, amtTune }, index) => {
      return {
        modeProdId: catalogDetail?.prodId || "",
        carId: catalogDetail?.carId || "",
        monthInfo,
        amtTune,
        tkvAmtTune: dcVals[index],
      };
    });
    try {
      setConfirmLoading(true);
      await editUcTkvAmtSet(body);
      if (onDataChange) onDataChange();
      if (onCancel) onCancel();
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleDcValChange = (index: number, value: number) => {
    const newDcVals = dcVals.concat();
    newDcVals[index] = value;
    setDcVals(newDcVals);
  };

  /**
   * Render Helpers
   */

  return (
    <Modal
      confirmLoading={confirmLoading}
      onOpen={onModalOpen}
      visible={visible}
      onCancel={onCancel}
      title="인수가 수정"
      footer={[
        <Button
          key="reset"
          type="default"
          loading={confirmLoading}
          onClick={handleReset}
        >
          초기화
        </Button>,
        <Button key="cancel" type="default" onClick={onCancel}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={confirmLoading}
          onClick={handleSubmit}
        >
          확인
        </Button>,
      ]}
    >
      {dataFetching ? (
        <Spin />
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>개월수</th>
              {tkvAmtSetlist.map(({ monthInfo }, index) => {
                return <th key={index.toString()}>{monthInfo}개월</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>인수가</th>
              {tkvAmtSetlist.map(({ tkvAmt }, index) => {
                return (
                  <td key={index.toString()}>
                    {Number(tkvAmt).toLocaleString()}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th>조정인수가</th>
              {tkvAmtSetlist.map(({ tkvAmtTune }, index) => {
                return (
                  <td key={index.toString()}>
                    <Input
                      type="number"
                      defaultValue={tkvAmtTune}
                      onChange={({ target: { value } }) =>
                        handleDcValChange(index, Number(value))
                      }
                    />
                  </td>
                );
              })}
            </tr>
            <tr className="rate-control">
              <th>조정</th>
              {tkvAmtSetlist.map(({ tkvAmtTune, tkvAmt }, index) => {
                return (
                  <td key={index.toString()}>
                    {Number(tkvAmtTune) - Number(tkvAmt) > 0 && "+"}
                    {Number(tkvAmtTune) - Number(tkvAmt) < 0 && "-"}
                    {(Number(tkvAmtTune) - Number(tkvAmt)).toLocaleString()}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </StyledTable>
      )}
    </Modal>
  );
};

TkvAmtSetModal.defaultProps = {};
export default TkvAmtSetModal;
