import React, { useState } from "react";
import { FormikProps, FormikValues } from "formik";
import { ModalProps } from "../../../../types";

// models
import TagMaster from "@models/TagMaster";

// components
import Modal from "@components/shared/feedback/antd/Modal";
import FormBuilder from "@components/shared/data-entry/FormBuilder";

// apis
import { addTagMaster, editTagMaster } from "@apis/tag-master";
import {
  TagMasterAddParam,
  TagMasterEditParam,
} from "@apis/tag-master/types";

// utils
import { getErrorMessage } from "@utils/common-utils";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "@utils/render-utils";
import {
  createForms,
  createInitialValues,
  createInitialValuesFromTagMaster,
  createValidationSchema,
} from "./utils/form-utils";

interface Props extends ModalProps {
  tagMaster?: TagMaster;
}

export const verticalFormLayout = {
  labelCol: {
    xl: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xl: { span: 24 },
    lg: { span: 24 },
  },
};

const TagMasterFormModal: React.FC<Props> = (props: Props) => {
  let formik: FormikProps<FormikValues>;
  const forms = createForms();
  const validationSchema = createValidationSchema();
  const { visible, onCancel, tagMaster, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(
    createInitialValues()
  );
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onModalOpen = async () => {
    setDataFetching(true);
    if (tagMaster) {
      setInitialValues(createInitialValuesFromTagMaster(tagMaster));
    } else {
      setInitialValues(createInitialValues());
    }
    setDataFetching(false);
  };

  /**
   * Private Functions
   */

  const getModalTitle = () => {
    if (tagMaster) return "태그수정";
    return "태그등록";
  };

  const requestAddTagMaster = async (params: TagMasterAddParam) => {
    setConfirmLoading(true);
    try {
      await addTagMaster(params);
      alertSuccess("상품태그가 등록되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  const requestEditTagMaster = async (
    tagId: string,
    params: TagMasterEditParam
  ) => {
    setConfirmLoading(true);
    try {
      await editTagMaster(tagId, params);
      alertSuccess("상품태그정보가 변경되었습니다.");
      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoading(false);
    }
  };

  /**
   * Event Actions
   */

  const handleSubmit = async (values: FormikValues) => {
    if (tagMaster) {
      await requestEditTagMaster(tagMaster.tagId, values as TagMasterEditParam);
    } else {
      await requestAddTagMaster(values as TagMasterAddParam);
    }
  };

  /**
   * Render Helpers
   */

  const renderDetail = () => {
    return (
      <FormBuilder
        formRef={(ref) => {
          formik = ref;
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
        forms={forms}
        onSubmit={handleSubmit}
        {...verticalFormLayout}
      />
    );
  };

  return (
    <>
      <Modal
        size="small"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        onOk={() => {
          formik?.handleSubmit();
        }}
        title={getModalTitle()}
        confirmLoading={confirmLoading}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
    </>
  );
};

TagMasterFormModal.defaultProps = {
  onCancel: () => {},
  visible: false,
};
export default TagMasterFormModal;
