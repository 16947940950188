import { CSSProperties } from "react";
import {
  TAG_STYLE_CD_1,
  TAG_STYLE_CD_2,
  TAG_STYLE_CD_3,
  TAG_STYLE_CD_4,
  TAG_STYLE_CD_5,
  TAG_STYLE_CD_6,
  // ! 프로모션 Tag
  TAG_STYLE_CD_11,
  // ! 신차 신규 스타일 Tags
  TAG_STYLE_CD_A54021,
  TAG_STYLE_CD_A54022,
  TAG_STYLE_CD_A54023,
  TAG_STYLE_CD_A54024,
  TAG_STYLE_CD_A54025,
  TAG_STYLE_CD_A54026,
  TAG_STYLE_CD_A54027,
  TAG_STYLE_CD_A54031,
  TAG_STYLE_CD_A54032,
  TAG_STYLE_CD_A54033,
  TAG_STYLE_CD_A54034,
  TAG_STYLE_CD_A54035,
  TAG_STYLE_CD_A54036,
  TAG_STYLE_CD_A54037,
  TAG_STYLE_CD_A54041,
  TAG_STYLE_CD_A54042,
  TAG_STYLE_CD_A54043,
  TAG_STYLE_CD_A54044,
  TAG_STYLE_CD_A54045,
  TAG_STYLE_CD_A54046,
  TAG_STYLE_CD_A54047,
  TAG_STYLE_CD_A54051,
  TAG_STYLE_CD_A54052,
  TAG_STYLE_CD_A54053,
  TAG_STYLE_CD_A54054,
  TAG_STYLE_CD_A54055,
  TAG_STYLE_CD_A54056,
  TAG_STYLE_CD_A54057,
  // TAG_STYLE_CD_62,
  // TAG_STYLE_CD_63,
  // TAG_STYLE_CD_64,
  // TAG_STYLE_CD_65,
  // TAG_STYLE_CD_66,
  // ! 월렌트 신규 스타일 Tags
  TAG_STYLE_CD_661,
  TAG_STYLE_CD_662,
  TAG_STYLE_CD_663,
  TAG_STYLE_CD_664,
  TAG_STYLE_CD_665,
  TAG_STYLE_CD_666,
  TAG_STYLE_CD_667,
  TAG_STYLE_CD_A66021,
  TAG_STYLE_CD_A66022,
  TAG_STYLE_CD_A66023,
  TAG_STYLE_CD_A66024,
  TAG_STYLE_CD_A66025,
  TAG_STYLE_CD_A66026,
  TAG_STYLE_CD_A66027,
  TAG_STYLE_CD_A66028,
  TAG_STYLE_CD_A66031,
  TAG_STYLE_CD_A66032,
  TAG_STYLE_CD_A66033,
  TAG_STYLE_CD_A66034,
  TAG_STYLE_CD_A66035,
  TAG_STYLE_CD_A66036,
  TAG_STYLE_CD_A66037,
  TAG_STYLE_CD_A66038,
  TAG_STYLE_CD_A66041,
  TAG_STYLE_CD_A66042,
  TAG_STYLE_CD_A66043,
  TAG_STYLE_CD_A66044,
  TAG_STYLE_CD_A66045,
  TAG_STYLE_CD_A66046,
  TAG_STYLE_CD_A66047,
  TAG_STYLE_CD_A66048,
  TAG_STYLE_CD_A66051,
  TAG_STYLE_CD_A66052,
  TAG_STYLE_CD_A66053,
  TAG_STYLE_CD_A66054,
  TAG_STYLE_CD_A66055,
  TAG_STYLE_CD_A66056,
  TAG_STYLE_CD_A66057,
  TAG_STYLE_CD_A66058,
} from "../../../../../constants/enums";

export type TagStyleCode =
  // ! ASIS 스타일 Tags
  | "A54001" // Grey + Fill
  | "A54002" // Red + Fill
  | "A54003" // Red + Line;
  | "A54004" // Blue + Line
  | "A54005" // Black + Line
  | "A54006" // Default + Line
  // ! 프로모션 Tag
  | "A54011" // 프로모션 Tag
  // ! 신차 신규 스타일 Tags
  | "A54021" // type A 1 - Pink + Line
  | "A54022" // type A 2 - Purple + Line
  | "A54023" // type A 3 - Blue + Line
  | "A54024" // type A 4 - Grey + Line
  | "A54025" // type A 5 - Light Green + Line
  | "A54026" // type A 6 - Green + Line
  | "A54027" // type A 7 - Orange + Line
  | "A54031" // type B 1 - Pink(Fill) + Line
  | "A54032" // type B 2 - Purple(Fill) + Line
  | "A54033" // type B 3 - Blue(Fill) + Line
  | "A54034" // type B 4 - Grey(Fill) + Line
  | "A54035" // type B 5 - Light(Fill) Green + Line
  | "A54036" // type B 6 - Green(Fill) + Line
  | "A54037" // type B 7 - Orange(Fill) + Line
  | "A54041" // type C 1 - Pink + Fill
  | "A54042" // type C 2 - Purple + Fill
  | "A54043" // type C 3 - Blue + Fill
  | "A54044" // type C 4 - Grey + Fill
  | "A54045" // type C 5 - Light Green + Fill
  | "A54046" // type C 6 - Green + Fill
  | "A54047" // type C 7 - Orange + Fill
  | "A54051" // type D 1 - Pink + None
  | "A54052" // type D 2 - Purple + None
  | "A54053" // type D 3 - Blue + None
  | "A54054" // type D 4 - Grey + None
  | "A54055" // type D 5 - Light Green + None
  | "A54056" // type D 6 - Green + None
  | "A54057" // type D 7 - Orange + None
  // | "A66002" // Red2 + Line
  // | "A66003" // Blue2 + Line
  // | "A66004" // Lightblue + Line
  // | "A66005" // Green + Line
  // | "A66006" // Orange + Line
  // ! 월렌트 신규 스타일 Tags
  | "A66011" // Orange + Fill
  | "A66012" // Pink + Fill
  | "A66013" // Green + Fill
  | "A66014" // Light Green + Fill
  | "A66015" // Blue + Fill
  | "A66016" // Purple + Fill
  | "A66017" // Red + Fill
  // ! 월렌트 신규 타입지정 스타일 Tags
  | "A66021" // type A 1 - Pink + Line
  | "A66022" // type A 2 - Purple + Line
  | "A66023" // type A 3 - Blue + Line
  | "A66024" // type A 4 - Grey + Line
  | "A66025" // type A 5 - Light Green + Line
  | "A66026" // type A 6 - Green + Line
  | "A66027" // type A 7 - Orange + Line
  | "A66028" // type A 8 - RED + Line
  | "A66031" // type B 1 - Pink(Fill) + Line
  | "A66032" // type B 2 - Purple(Fill) + Line
  | "A66033" // type B 3 - Blue(Fill) + Line
  | "A66034" // type B 4 - Grey(Fill) + Line
  | "A66035" // type B 5 - Light Green(Fill) + Line
  | "A66036" // type B 6 - Green(Fill) + Line
  | "A66037" // type B 7 - Orange(Fill) + Line
  | "A66038" // type B 8 - RED(Fill) + Line
  | "A66041" // type C 1 - Pink + Fill
  | "A66042" // type C 2 - Purple + Fill
  | "A66043" // type C 3 - Blue + Fill
  | "A66044" // type C 4 - Grey + Fill
  | "A66045" // type C 5 - Light Green + Fill
  | "A66046" // type C 6 - Green + Fill
  | "A66047" // type C 7 - Orange + Fill
  | "A66048" // type C 8 - RED + Fill
  | "A66051" // type D 1 - Pink + None
  | "A66052" // type D 2 - Purple + None
  | "A66053" // type D 3 - Blue + None
  | "A66054" // type D 4 - Grey + None
  | "A66055" // type D 5 - Light Green + None
  | "A66056" // type D 6 - Green + None
  | "A66057" // type D 7 - Orange + None
  | "A66058" // type D 8 - RED + None
;

export type TagPositionCode =
  | "A55001" // 상단
  | "A55002"; // 하단

export type TagLevelCode =
  | "A69001" // 상품(MP)
  | "A69002" // 트림(MG)
  | "A69003" // 공통
;

export const TAG_STYLE_INFO: Record<TagStyleCode, CSSProperties> = {
  [TAG_STYLE_CD_1]: {
    backgroundColor: "#5B6286",
    borderRadius: "12px",
    color: "white",
    border: "none",
    padding: "3px 12px",
  },
  [TAG_STYLE_CD_2]: {
    backgroundColor: "#F65227",
    color: "white",
    borderRadius: "12px",
    border: "none",
    padding: "3px 12px",
  },
  [TAG_STYLE_CD_3]: {
    backgroundColor: "white",
    color: "#D63037",
    borderRadius: "2px",
    border: "1px solid #D63037",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_4]: {
    backgroundColor: "white",
    color: "#38289A",
    borderRadius: "2px",
    border: "1px solid #38289A",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_5]: {
    backgroundColor: "white",
    color: "#262628",
    borderRadius: "2px",
    border: "1px solid #262628",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_6]: {
    backgroundColor: "white",
    color: "#77777A",
    borderRadius: "2px",
    border: "1px solid #C4C5C9",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_11]: {
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "9px 10px",
    borderRadius: "6px",
    background: "linear-gradient(90deg, #3122C6 -0.18%, #E60069 94.92%)",
    color: "#fff",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
  },
  [TAG_STYLE_CD_A54021]: { // TODO: 스타일 수정 필요 - type A 1 - Pink + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#fff",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A54022]: { // TODO: 스타일 수정 필요 - type A 2 - Purple + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#fff",
    color: "#9250D4",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A54023]: { // TODO: 스타일 수정 필요 - type A 3 - Blue + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#fff",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A54024]: { // TODO: 스타일 수정 필요 - type A 4 - Grey + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#fff",
    color: "#77777A",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A54025]: { // TODO: 스타일 수정 필요 - type A 5 - Light Green + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#fff",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A54026]: { // TODO: 스타일 수정 필요 - type A 6 - Green + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#fff",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A54027]: { // TODO: 스타일 수정 필요 - type A 7 - Orange + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#fff",
    color: "#F1741A",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A54031]: { // TODO: 스타일 수정 필요 - type B 1 - Pink(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "rgba(218, 84, 172, 0.08)",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A54032]: { // TODO: 스타일 수정 필요 - type B 2 - Purple(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "rgba(146, 80, 212, 0.08)",
    color: "#9250D4",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A54033]: { // TODO: 스타일 수정 필요 - type B 3 - Blue(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "rgba(59, 112, 214, 0.08)",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A54034]: { // TODO: 스타일 수정 필요 - type B 4 - Grey(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "rgba(119, 119, 122, 0.08)",
    color: "#77777A",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A54035]: { // TODO: 스타일 수정 필요 - type type B 5 - Light(Fill) Green + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "rgba(18, 166, 185, 0.08)",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A54036]: { // TODO: 스타일 수정 필요 - type B 6 - Green(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "rgba(27, 172, 85, 0.08)",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A54037]: { // TODO: 스타일 수정 필요 - type B 7 - Orange(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "rgba(241, 116, 26, 0.08)",
    color: "#F1741A",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A54041]: { // TODO: 스타일 수정 필요 - type C 1 - Pink + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#FFEBF8",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54042]: { // TODO: 스타일 수정 필요 - type C 2 - Purple + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#F3EEFF",
    color: "#9250D4",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54043]: { // TODO: 스타일 수정 필요 - type C 3 - Blue + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#E8EFFF",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54044]: { // TODO: 스타일 수정 필요 - type C 4 - Grey + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#EFEFEF",
    color: "#77777A",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54045]: { // TODO: 스타일 수정 필요 - type type C 5 - Light Green + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#E2FBFD",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54046]: { // TODO: 스타일 수정 필요 - type C 6 - Green + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#E3FBEC",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54047]: { // TODO: 스타일 수정 필요 - type C 7 - Orange + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "#FFF0E6",
    color: "#F1741A",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54051]: { // TODO: 스타일 수정 필요 - type D 1 - Pink + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "transparent",
    color: "#EB444B",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54052]: { // TODO: 스타일 수정 필요 - type D 2 - Purple + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "transparent",
    color: "#9250D4",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54053]: { // TODO: 스타일 수정 필요 - type D 3 - Blue + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "transparent",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54054]: { // TODO: 스타일 수정 필요 - type D 4 - Grey + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "transparent",
    color: "#5C5C5C",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54055]: { // TODO: 스타일 수정 필요 - type D 5 - Light Green + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "transparent",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54056]: { // TODO: 스타일 수정 필요 - type D 6 - Green + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "transparent",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54057]: { // TODO: 스타일 수정 필요 - type D 7 - Orange + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    borderRadius: "4px",
    background: "transparent",
    color: "#F1741A",
    gap: "4px",
    fontSize:"11px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  // [TAG_STYLE_CD_62]: {
  //   color: "#EB444B",
  //   backgroundColor: "#FFF7F7",
  //   borderColor: "#FFBCC3"
  // },
  // [TAG_STYLE_CD_63]: {
  //   color: "#6055EB",
  //   backgroundColor: "#F8F8FD",
  //   borderColor: "#CBCBFA"
  // },
  // [TAG_STYLE_CD_64]: {
  //   color: "#3993E7",
  //   backgroundColor: "#F3F9FE",
  //   borderColor: "#85BBF0",
  // },
  // [TAG_STYLE_CD_65]: {
  //   color: "#00AA44",
  //   backgroundColor:"#F4FBF7",
  //   borderColor: "#95D9B3",
  // },
  // [TAG_STYLE_CD_66]: {
  //   color: "#FF7A00",
  //   backgroundColor:"#FFF5EB",
  //   borderColor: "#FFC48D",
  // },
  [TAG_STYLE_CD_661]: {
    color: "#FF7A00",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#FFF2E5",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_662]: {
    color: "#E94CA1",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#FCE1F6",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_663]: { // Green + Fill
    color: "#06B42D",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#E3FAE2",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_664]: { // Light Green + Fill
    color: "#00A8A8",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#D7FCFC",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_665]: { // Blue + Fill
    color: "#2791F2",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#D0EEFF",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_666]: { // Purple + Fill
    color: "#9961F4",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#E7DFFF",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_667]: { // TODO: 색상 수정 - Red + Fill
    color: "#EB444B",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#FFECEC",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_A66021] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A66022] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A66023] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A66024] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#77777A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A66025] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A66026] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A66027] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A66028] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #EB444B"
  },
  [TAG_STYLE_CD_A66031] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(218, 84, 172, 0.08)",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A66032] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(146, 80, 212, 0.08)",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A66033] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(59, 112, 214, 0.08)",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A66034] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(119, 119, 122, 0.08)",
    color: "#77777A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A66035] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(18, 166, 185, 0.08)",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A66036] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(27, 172, 85, 0.08)",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A66037] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(241, 116, 26, 0.08)",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A66038] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "rgba(255, 236, 236, 1)",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #EB444B"
  },
  [TAG_STYLE_CD_A66041] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#FFEBF8",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66042] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#F3EEFF",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66043] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#E8EFFF",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66044] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#EFEFEF",
    color: "#77777A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66045] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#E2FBFD",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66046] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#E3FBEC",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66047] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#FFF0E6",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66048] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "#FFECEC",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66051] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66052] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66053] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66054] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#5C5C5C",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66055] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66056] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66057] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66058] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "5.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
};

export const MOB_TAG_STYLE_INFO: Record<TagStyleCode, CSSProperties> = {
  [TAG_STYLE_CD_1]: {
    backgroundColor: "#5B6286",
    borderRadius: "12px",
    color: "white",
    border: "none",
    padding: "3px 6px",
  },
  [TAG_STYLE_CD_2]: {
    backgroundColor: "#F65227",
    color: "white",
    borderRadius: "12px",
    border: "none",
    padding: "3px 6px",
  },
  [TAG_STYLE_CD_3]: {
    backgroundColor: "white",
    color: "#D63037",
    borderRadius: "2px",
    border: "1px solid #D63037",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_4]: {
    backgroundColor: "white",
    color: "#38289A",
    borderRadius: "2px",
    border: "1px solid #38289A",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_5]: {
    backgroundColor: "white",
    color: "#262628",
    borderRadius: "2px",
    border: "1px solid #262628",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_6]: {
    backgroundColor: "white",
    color: "#77777A",
    borderRadius: "2px",
    border: "1px solid #C4C5C9",
    padding: "2px 6px",
  },
  [TAG_STYLE_CD_11]: {
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "9px 10px",
    borderRadius: "6px",
    background: "linear-gradient(90deg, #3122C6 -0.18%, #E60069 94.92%)",
    color: "#fff",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
  },
  [TAG_STYLE_CD_A54021]: { // TODO: 스타일 수정 필요 - type A 1 - Pink + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A54022]: { // TODO: 스타일 수정 필요 - type A 2 - Purple + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#9250D4",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A54023]: { // TODO: 스타일 수정 필요 - type A 3 - Blue + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A54024]: { // TODO: 스타일 수정 필요 - type A 4 - Grey + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#77777A",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A54025]: { // TODO: 스타일 수정 필요 - type A 5 - Light Green + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A54026]: { // TODO: 스타일 수정 필요 - type A 6 - Green + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A54027]: { // TODO: 스타일 수정 필요 - type A 7 - Orange + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#F1741A",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A54031]: { // TODO: 스타일 수정 필요 - type B 1 - Pink(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "rgba(218, 84, 172, 0.08)",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A54032]: { // TODO: 스타일 수정 필요 - type B 2 - Purple(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "rgba(146, 80, 212, 0.08)",
    color: "#9250D4",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A54033]: { // TODO: 스타일 수정 필요 - type B 3 - Blue(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "rgba(59, 112, 214, 0.08)",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A54034]: { // TODO: 스타일 수정 필요 - type B 4 - Grey(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "rgba(119, 119, 122, 0.08)",
    color: "#77777A",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A54035]: { // TODO: 스타일 수정 필요 - type type B 5 - Light(Fill) Green + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "rgba(18, 166, 185, 0.08)",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A54036]: { // TODO: 스타일 수정 필요 - type B 6 - Green(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "rgba(27, 172, 85, 0.08)",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A54037]: { // TODO: 스타일 수정 필요 - type B 7 - Orange(Fill) + Line
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "rgba(241, 116, 26, 0.08)",
    color: "#F1741A",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A54041]: { // TODO: 스타일 수정 필요 - type C 1 - Pink + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#FFEBF8",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54042]: { // TODO: 스타일 수정 필요 - type C 2 - Purple + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#F3EEFF",
    color: "#9250D4",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54043]: { // TODO: 스타일 수정 필요 - type C 3 - Blue + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#E8EFFF",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54044]: { // TODO: 스타일 수정 필요 - type C 4 - Grey + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#EFEFEF",
    color: "#77777A",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54045]: { // TODO: 스타일 수정 필요 - type type C 5 - Light Green + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#E2FBFD",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54046]: { // TODO: 스타일 수정 필요 - type C 6 - Green + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#E3FBEC",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54047]: { // TODO: 스타일 수정 필요 - type C 7 - Orange + Fill
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "#FFF0E6",
    color: "#ED6B0B",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54051]: { // TODO: 스타일 수정 필요 - type D 1 - Pink + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#EB444B",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54052]: { // TODO: 스타일 수정 필요 - type D 2 - Purple + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#9250D4",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54053]: { // TODO: 스타일 수정 필요 - type D 3 - Blue + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54054]: { // TODO: 스타일 수정 필요 - type D 4 - Grey + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#5C5C5C",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54055]: { // TODO: 스타일 수정 필요 - type D 5 - Light Green + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54056]: { // TODO: 스타일 수정 필요 - type D 6 - Green + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  [TAG_STYLE_CD_A54057]: { // TODO: 스타일 수정 필요 - type D 7 - Orange + None
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "3px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#F1741A",
    gap: "4px",
    fontSize:"10px",
    lineHeight: "100%",
    fontWeight: 400,
    border:"none",
  },
  // [TAG_STYLE_CD_62]: {
  //   color: "#EB444B",
  //   backgroundColor: "#FFF7F7",
  //   borderColor: "#FFBCC3"
  // },
  // [TAG_STYLE_CD_63]: {
  //   color: "#6055EB",
  //   backgroundColor: "#F8F8FD",
  //   borderColor: "#CBCBFA"
  // },
  // [TAG_STYLE_CD_64]: {
  //   color: "#3993E7",
  //   backgroundColor: "#F3F9FE",
  //   borderColor: "#85BBF0",
  // },
  // [TAG_STYLE_CD_65]: {
  //   color: "#00AA44",
  //   backgroundColor:"#F4FBF7",
  //   borderColor: "#95D9B3",
  // },
  // [TAG_STYLE_CD_66]: {
  //   color: "#FF7A00",
  //   backgroundColor:"#FFF5EB",
  //   borderColor: "#FFC48D",
  // },
  [TAG_STYLE_CD_661]: {
    fontSize:"12px",
    color: "#FF7A00",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#FFF2E5",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_662]: {
    fontSize:"12px",
    color: "#E94CA1",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#FCE1F6",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_663]: { // Green + Fill
    color: "#06B42D",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#E3FAE2",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_664]: { // Light Green + Fill
    color: "#00A8A8",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#D7FCFC",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_665]: { // Blue + Fill
    color: "#2791F2",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#D0EEFF",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_666]: { // Purple + Fill
    color: "#9961F4",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#E7DFFF",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_667]: { // TODO: 색상 수정 - Red + Fill
    color: "#EB444B",
    fontWeight: 700,
    padding: "1px 7px 1px",
    background: "#FFECEC",
    borderRadius: ".36rem",
    marginLeft: "5px",
    border:"none",
    lineHeight:"21px",
  },
  [TAG_STYLE_CD_A66021] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A66022] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A66023] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A66024] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#77777A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A66025] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A66026] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A66027] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A66028] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#fff",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #EB444B"
  },
  [TAG_STYLE_CD_A66031] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(218, 84, 172, 0.08)",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #DA54AC"
  },
  [TAG_STYLE_CD_A66032] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(146, 80, 212, 0.08)",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #9250D4"
  },
  [TAG_STYLE_CD_A66033] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(59, 112, 214, 0.08)",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #3B70D6"
  },
  [TAG_STYLE_CD_A66034] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(119, 119, 122, 0.08)",
    color: "#77777A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #77777A"
  },
  [TAG_STYLE_CD_A66035] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(18, 166, 185, 0.08)",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #12A6B9"
  },
  [TAG_STYLE_CD_A66036] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(27, 172, 85, 0.08)",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #1BAC55"
  },
  [TAG_STYLE_CD_A66037] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(241, 116, 26, 0.08)",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #F1741A"
  },
  [TAG_STYLE_CD_A66038] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "rgba(255, 236, 236, 1)",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"1px solid #EB444B"
  },
  [TAG_STYLE_CD_A66041] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#FFEBF8",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66042] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#F3EEFF",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66043] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#E8EFFF",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66044] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#EFEFEF",
    color: "#77777A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66045] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#E2FBFD",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66046] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#E3FBEC",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66047] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#FFF0E6",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66048] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "#FFECEC",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66051] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#DA54AC",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66052] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#9250D4",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66053] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#3B70D6",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66054] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#5C5C5C",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66055] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#12A6B9",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66056] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#1BAC55",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66057] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#F1741A",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
  [TAG_STYLE_CD_A66058] :{
    backgroundColor: "none",
    // display: "flex",
    alignItems: "center",
    padding: "4.5px 10px",
    borderRadius: "4px",
    background: "transparent",
    color: "#EB444B",
    gap: "4px",
    fontSize:"13px",
    lineHeight: "100%",
    fontWeight: 700,
    border:"none",
  },
};

export const getPcTagStyles = (tagStyleCd: TagStyleCode): CSSProperties => {
  return {
    fontSize: "12px",
    lineHeight: "initial",
    fontFamily: "Noto Sans KR",
    ...TAG_STYLE_INFO[tagStyleCd],
    // fontWeight: 500,
  };
};

export const getMobileTagStyles = (tagStyleCd: TagStyleCode): CSSProperties => {
  return {
    fontSize: "12px",
    lineHeight: "initial",
    fontFamily: "Noto Sans KR",
    ...MOB_TAG_STYLE_INFO[tagStyleCd],
    // fontWeight: 500,
    boxSizing: "border-box",
    // height: "18px",
    // padding: "1px 6px",
    // lineHeight: "14.5px",
    // marginLeft:"0",
  };
};
