import React, { useState } from "react";
import { ModalProps } from "../../../../../../types";
import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../../../CatalogTagManager/utils/table-utils";
import TagPreviewModal from "../TagPreviewModal";

// components
import Modal from "@components/shared/feedback/antd/Modal";
import ModalTableWrapper from "@components/shared/layout/ModalTableWrapper";
import ClientRowTable from "@components/shared/data-display/Tables/components/ClientRowTable";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "@components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import Button from "@components/shared/general/antd/Button";

// utils
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "@utils/render-utils";
import { getErrorMessage } from "@utils/common-utils";
import { createTagSetupCols } from "./utils/table-utils";

// models
import TagMaster from "@models/TagMaster";
import UsedCarCatalogDetail from "@models/UsedCarCatalogDetail";

// apis
import { fetchTagMasters } from "@apis/uc-tag-master";
import { linkTags, unlinkTags } from "@apis/uc-catalogs";

// constants
import { TOP_TAG_LIST } from "@constants/enums";

/**
 * 신차상품 <-> 태그 등록/등록해제 모달
 */

interface Props extends ModalProps {
  catalogDetail?: UsedCarCatalogDetail;
}

const CatalogTagSetupModal: React.FC<Props> = (props: Props) => {
  const cols = createTagSetupCols();

  const { visible, onCancel, onDataChange, catalogDetail } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [confirmLoading, setConfirmLoadng] = useState(false);
  const [tagMasters, setTagMasters] = useState<TagMaster[]>();
  const [checkedRow, setCheckedRows] = useState<TagMaster[]>([]);
  const [tagPreviewModalVisible, setTagPreviewModalVisible] = useState(false);

  const onModalOpen = async () => {
    setDataFetching(true);
    setCheckedRows([]);
    await requestFetchTagMasters();
    setDataFetching(false);
  };

  const requestFetchTagMasters = async () => {
    try {
      const { items } = await fetchTagMasters();
       // ! 월렌트 전용 신규 태그 스타일 제외
      setTagMasters(items.filter(v => !TOP_TAG_LIST.includes(v.pcTagStyl)));
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  const requestLinkCatalogs = async () => {
    setConfirmLoadng(true);
    try {
      // 기존에 연결된 태그 전체 unlink
      if (catalogDetail) {
        const { tagList, prodId } = catalogDetail;

        if (
            tagList
        ) {
          const tagSeqs = tagList.map(({ tagSeq }) => tagSeq);
          await unlinkTags(tagSeqs);
        }

        // 체크된 태그 전체 link
        const tagIds = checkedRow.map(({ tagId }) => tagId);
        await linkTags(prodId, tagIds);
        alertSuccess("상품 태그정보가 변경되었습니다.");
      }

      if (onCancel) onCancel();
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    } finally {
      setConfirmLoadng(false);
    }
  };

  const handleModalOk = async () => {
    await requestLinkCatalogs();
  };

  const handleCheckRowChanged = (rows: Array<TagMaster>) => {
    setCheckedRows(rows);
  };

  const renderDetail = () => {
    if (!catalogDetail) return "";

    return (
      <ModalTableWrapper
        headerTitle="태그 리스트"
        style={{
          position: "relative",
        }}
      >
        <ClientRowTable
          apiRef={(api) => {
            api.forEachNode((node) => {
              if (catalogDetail && catalogDetail.tagList) {
                const data = node.data as TagMaster;
                // eslint-disable-next-line react/prop-types
                const filtered = (catalogDetail.tagList).filter(({ tagId }) => {
                  return tagId === data.tagId;
                });
                if (filtered.length > 0) {
                  node.setSelected(true);
                }
              }
            });
          }}
          onCheckRowChanged={handleCheckRowChanged}
          cols={cols}
          rowData={tagMasters}
          frameworkComponents={{
            [PC_TAG_RENDERER]: pcTagCellRenderer,
            [MOBILE_TAG_RENDERER]: mobileTagRenderer,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button
            onClick={() => {
              setTagPreviewModalVisible(true);
            }}
          >
            미리보기
          </Button>
        </div>
      </ModalTableWrapper>
    );
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="태그 설정"
        confirmLoading={confirmLoading}
        onOk={handleModalOk}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <TagPreviewModal
        visible={tagPreviewModalVisible}
        onCancel={() => {
          setTagPreviewModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        tagMasters={checkedRow}
      />
    </>
  );
};

export default CatalogTagSetupModal;
